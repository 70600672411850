import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Row } from "react-flexbox-grid";
import styled from "@emotion/styled";

const StyledCard = styled.div`
  position: relative;
  min-height: 100px;
  background: ${(props) => props.theme.tertiaryColor};
  cursor: pointer;
  color: #242a36;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  padding-top: 30px;

  @media (min-width: 768px) {
    padding-top: 70px;
  }
`;

const StyledCardLink = styled.a`
  width: 50%;
  box-sizing: border-box;
  padding: 10px;
  z-index: 999;
  text-align: center;

  @media (min-width: 768px) {
    width: 20%;
  }
`;

const IconContainer = styled.div`
  padding: 10px;
  background: #fff;
  border-radius: 100px;
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: ${props => props.theme.tintedShadow};
  box-shadow: 0px 2px 5px rgba(120, 87, 255, 0.3);
  box-shadow: 0px 5px 20px rgb(120 87 255 / 25%);
  height: 50px;
  width: 50px;

  @media (min-width: 768px) {
    top: -45px;
    height: 90px;
    width: 90px;
  }
`

const Icon = styled(LazyLoadImage)`
  margin: 0 auto;
  min-height: 50px;
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    min-height: 55px;
    width: 68px;
    height: 68px;
  }
`;

const TaxTitle = styled.h3`
  display: block;
  text-align: center;
  padding: 5px 0;
  white-space: nowrap;
  font-size: 18px;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

class FeaturedTaxonomyGrid extends React.Component {
  render() {
    const { data, scrollPosition } = this.props;

    return (
      <Row style={{ overflow: "hidden" }} className="pt-5">
        {data?.map((item, i) => {
          return (
            <TaxCard item={item} key={i} scrollPosition={scrollPosition} />
          );
        })}
      </Row>
    );
  }
}

export default FeaturedTaxonomyGrid;

class TaxCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }
  render() {
    const { item, scrollPosition } = this.props;
    return (
      <StyledCardLink className="mb-3" href={item.path}>
        <StyledCard
          onMouseOut={() => this.setState({ hovered: false })}
          onMouseOver={() => this.setState({ hovered: true })}
          className="px-1 px-md-3 mb-4 mb-md-5 pb-4"
        >
          <IconContainer>
            <Icon
              src={item.icon /*this.state.hovered ? item.iconHover : item.icon*/}
              scrollPosition={scrollPosition}
              alt={item.name}
            />
          </IconContainer>
          <TaxTitle parent className="my-2">{item?.["list-title"] || item.name}</TaxTitle>
          <span>{item?.teaser}</span>
        </StyledCard>
      </StyledCardLink>
    );
  }
}
