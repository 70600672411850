import React from "react"
import styled from "@emotion/styled"
import { Grid, Row, Col } from "react-flexbox-grid"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Container = styled.div`
  overflow-x: scroll;
  background: #dcdbeb;

  .row {
    flex-wrap: nowrap;
  }
`

const Tab = styled(Col)`
  background-color: ${(props) =>
    props.active ? props.theme.tertiaryColor : "transparent"};
  border-radius: 0;
  border-top-left-radius: ${(props) => (props.active ? "5px" : "0")};
  border-top-right-radius: ${(props) => (props.active ? "5px" : "0")};
  border: 1px solid #dfdfdf;
  margin: 0;
  margin-top: 5px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: 300;
  white-space: normal;
  padding: 10px 5px;
  font-size: 14px;
  border: 0;
  min-width: 110px;
  border-right: 1px solid #d2d1e1;

  a {
    color: #555;
    color: ${(props) => (props.active ? "#7857ff" : "#555")};
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 25px;
    height: 25px;
    opacity: ${(props) => (props.active ? "1" : "0.55")};
  }

  @media (min-width: 992px) {
    white-space: nowrap;

    a {
    }
  }
`

const icons = [
  {
    id: "recommended",
    icon: "/tabIcons/defaultInactive.svg",
    iconInactive: "/tabIcons/default.svg",
  },
  {
    id: "Allan__MenuItem__ckfz7j52w694780oq0sp01vamj",
    icon: "/tabIcons/freespins.svg",
  },
  {
    id: "Allan__MenuItem__ckfz7jlz0695030oq0g94fbqbt",
    icon: "/tabIcons/slots.svg",
  },
  {
    id: "Allan__MenuItem__ckfz97f1j772300oq0tp4r0pxw",
    icon: "/tabIcons/netent.svg",
  },
  {
    id: "Allan__MenuItem__ckh61j6cj2858060noifwtpof2q",
    icon: "/tabIcons/evolution.svg",
  },
  {
    id: "Allan__MenuItem__ckh61kbv32859350noizd2f007m",
    icon: "/tabIcons/skrill.svg",
  },
]

const TabBar = (props) => {
  const {
    menu,
    className,
    activeTab = true,
    pageContext,
    scrollPosition,
  } = props
  const { themeSettings = {} } = pageContext
  const { hideMenus, overrideTabBarTitle } = themeSettings

  const tabs = [
    {
      id: "recommended",
      title: "Recommended casinos",
      href: activeTab ? "#" : "/",
      active: activeTab,
    },
    ...menu.slice(0, 5),
  ]

  return (
    <Container className={className}>
      <Grid>
        <Row>
          {tabs.map((tab, i) => {
            if (hideMenus?.enabled && hideMenus?.ids?.indexOf(tab.id) > -1) {
              return null
            }

            if (overrideTabBarTitle && tab.id === overrideTabBarTitle?.selector) {
              tab.title = overrideTabBarTitle?.title
            }

            return (
              <Tab
                key={i}
                className={"d-block d-md-block"}
                active={tab.active ? 1 : undefined}
              >
                <a href={tab.href}>
                  <LazyLoadImage
                    src={
                      (tab.active
                        ? icons?.[i]?.iconInactive
                        : icons?.[i]?.icon) || "/tabIcons/defaultInactive.svg"
                    }
                    className="mb-2"
                    alt={String(tab?.title)}
                    scrollPosition={scrollPosition}
                  />
                  {tab.title}
                </a>
              </Tab>
            )
          })}
        </Row>
      </Grid>
    </Container>
  )
}
export default TabBar
