import React, { useState } from "react"
import { Row, Col } from "react-flexbox-grid"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { formatDate } from "../../utils/index"
import cloudinary from "../../utils/cloudinary"
import styled from "@emotion/styled"


const Container = styled.div`
  position: relative;
`

const PostsContainer = styled(Row)`
  flex-direction: row;
  display: flex;
  z-index: 999;
  position: relative;
  text-align: left;
  flex-wrap: ${props => props.multiline ? "wrap" : "nowrap"};
  overflow-x: scroll;

  a {
    color: #333;
  }
  a:hover {
    color: #333;
  }
`

const Post = styled(Col)`
  @media only screen and (max-width: 768px) {
    max-width: ${props => props.multiline ? "100%" : "calc(100% - 80px)"};
  }

  div.wrapper {
    border-radius: ${props => props.theme.borderRadius};
    overflow: hidden;
    background: transparent;
    display: flex;
    flex-direction: column;
  }

  div.content {
    padding: 20px;
    border: 2px solid #d6d5f3;
    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: relative;

    div.date {
      transform: translateY(-100%);
      color: #fff;
      background: ${props => props.theme.primaryColor};
      display: block;
      font-size: 12px;
      position: absolute;
      top: 0;
      padding: 5px 10px;
    }

    span.excerpt {
      margin-top: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 13px;
      color: #000;

      a {
        text-decoration: underline;
        color: ${props => props.theme.secondaryColor};
      }
    }

    span.excerpt p {
      font-size: 13px;
      margin: 0;
    }

  }
  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
  h2, h3 {
    margin-top: 0;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px !important;

    a {

    }
  }
`

export default props => {
  const [loaded, setLoaded] = useState(false)
  const { posts, scrollPosition, backgroundColor = "#fff", className, count = 4, multiline = false, h2titles = false} = props

  if (!posts) return null

  return (
    <Container className={className} multiline={multiline} backgroundColor={backgroundColor}>
      <LazyLoadComponent
        scrollPosition={scrollPosition}
        afterLoad={() => setLoaded(true)}
      >
        {" "}
      </LazyLoadComponent>

      <PostsContainer multiline={multiline}>
        {posts?.slice(0, multiline ? posts.length : count).map((post, i) => {
          return (
            <Post xs={12} md={props?.md ? props.md : 3} key={i} className="mb-3 mb-md-3" multiline={multiline}>
              <div className="wrapper">
                <img
                  src={
                    (loaded &&
                      cloudinary.url(post.properties?.["featured-image"]?.resolved?.[0]
                        ?.url, { width: 560 })) ||
                    null
                  }
                  alt={post.title}
                />

                <div className="content">
                  <div className="date">{post?.properties?.["published-at"]?.data?.value ? formatDate(post?.properties?.["published-at"]?.data?.value) : post.createdAt}</div>
                  {h2titles ? (
                    <h2 className="my-0 mb-3">
                      <a href={post.path}>
                        {post.title}
                      </a>
                    </h2>
                  ):(
                    <h3 className="my-0 mb-3">
                      <a href={post.path}>
                        {post.title}
                      </a>
                    </h3>
                  )}

                  <span
                    className="excerpt"
                    dangerouslySetInnerHTML={{
                      __html: post?.content,
                    }}
                  />
                </div>
              </div>
            </Post>
          )
        })}


      </PostsContainer>
    </Container>
  )
}
