import React, { useState } from "react"
import styled from "@emotion/styled"
import { Col, Row } from "react-flexbox-grid"
import { ArrowRight } from "./styledComponents"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-top-right-radius: ${(props) => props.theme.borderRadius};
`

const StyledLink = styled.a`
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: rgb(51, 51, 51);
  font-weight: normal;
  padding: 0;

  :hover {
    color: rgb(242, 16, 90);
    text-decoration: none;
  }

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 21px;
  }
`

const TabBar = styled(Row)`
  overflow-x: scroll;
  padding-bottom: 9px;
  background: #fff;
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-top-right-radius: ${(props) => props.theme.borderRadius};
`

const Tab = styled.div`
  font-size: 14px;
  background: ${(props) => (props.active ? props.theme.primaryColor : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  text-align: center;
  border-top-left-radius: ${(props) =>
    props.roundedLeft ? props.theme.borderRadius : 0};
  border-top-right-radius: ${(props) =>
    props.roundedRight ? props.theme.borderRadius : 0};
  border-bottom: 1px solid #ededee;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 130px;
  padding: 0 20px;
  flex: 1;

  &:after {
    content: " ";
    position: absolute;
    bottom: -9px;
    left: 50%;
    background: ${(props) => props.theme.primaryColor};
    display: ${(props) => (props.active ? "block" : "none")};
    width: 12px;
    height: 12px;
    transform: rotate(45deg) translateX(-50%);
  }

  img {
    filter: ${(props) =>
      props.active
        ? "invert(100%) sepia(0%) saturate(0%) hue-rotate(131deg) brightness(200%) contrast(101%)"
        : "none"};
    width: 32px;
    height: 32px;
    margin: 0 auto;
  }
`

const Content = styled(Row)`
  background: #fff;
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};
`

const ContentCol = styled(Col)`
  width: 50%;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    width: 20%;
  }
`

export default (props) => {
  const [activeTab, setActiveTab] = useState(0)
  let { data, pageContext, scrollPosition } = props
  const { themeSettings = {} } = pageContext
  const { disableBonuses = false } = themeSettings
  const bonusTaxonomies = ["recU5t2F3JOC1LAiD"]

  data = data.filter((taxonomy) => {
    if (
      !taxonomy?.item?.path ||
      !taxonomy?.item?.published ||
      !taxonomy?.children?.length
    ) {
      return false
    } else if (
      disableBonuses &&
      bonusTaxonomies.indexOf(taxonomy?.item?.allanId) > -1
    ) {
      return false
    }
    return true
  })

  if (!data.length) return null

  return (
    <Container>
      <TabBar className="flex-nowrap mx-0">
        {data?.map((taxonomy, i) => {
          return (
            <Tab
              className="tab py-4"
              active={activeTab === i}
              onClick={() => setActiveTab(i)}
              roundedLeft={i === 0}
              roundedRight={i === data?.length - 1}
            >
              <LazyLoadImage
                src={taxonomy.icon || "/img/globe.svg"}
                className="mb-2"
                alt={String(taxonomy?.item?.name)}
                scrollPosition={scrollPosition}
              />
              {taxonomy?.item?.name}
            </Tab>
          )
        })}
      </TabBar>
      {data?.map((taxonomy, i) => {
        return (
          <Content
            className="mx-0 px-1 px-xl-3 pb-4 pt-0 pt-md-4"
            style={{ display: i === activeTab ? "flex" : "none" }}
          >
            {taxonomy?.children?.map((child, j) => {
              return (
                <ContentCol key={j} className="py-2 pt-0 pt-md-2 px-1 px-xl-3">
                  <StyledLink href={child?.path} className="my-1">
                    <ArrowRight className="mr-2" />
                    {child?.["list-title"] || child.name}
                  </StyledLink>
                </ContentCol>
              )
            })}
          </Content>
        )
      })}
    </Container>
  )
}
