import React from "react"
import styled from "@emotion/styled"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { Helmet } from "react-helmet"
import { dynamicString } from "../../../utils/index"
import Layout from "../../components/layout"
import ProviderList from "../../components/providerList"
import FeaturedProviders from "../../components/featuredProviders"
import FeaturedTaxonomyGrid from "../../components/featuredTaxonomyGrid"
import ExpandableText from "../../components/expandableText"
import BrowseProvidersBy from "../../components/browseProvidersBy"
import ContentSections from "../../components/contentSections"
import Faq from "../../components/faq"
import PostGrid from "../../components/postGrid"
import TabBar from "../../components/tabBar"
import { Grid, Row, Col } from "react-flexbox-grid"
import {
  ArrowRight,
  Button,
  HeroSection,
  Section,
  ContentSectionsMenu,
} from "../../components/styledComponents"
import ProviderTable from "../../components/providerTable"

const StyledSection = styled(Section)`
  background: ${(props) => props.theme.tertiaryColor};
`

const HeroImg = styled.img`
  position: relative;
  width: 318px;
  height: 241px;
  object-fit: contain;

  @media only screen and (min-width: 1200px) {
    width: 376px;
    height: 286px;
  }

  @media only screen and (min-width: 1400px) {
    width: 390px;
    height: 337px;
  }
`

function jsonSchemaOrganization(props) {
  const { pageContext } = props
  const { site, themeSettings } = pageContext

  return {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: site?.name,
    description: site?.description,
    logo: themeSettings["logotype"]
      ? themeSettings["logotype"]
      : site?.properties?.logotype?.resolved?.[0]?.url,
    url:
      site?.url.indexOf("https://") === 0 ? site?.url : "https://" + site?.url,
    sameAs: [
      "https://www.facebook.com/CasinoRankCOM",
      "https://twitter.com/CasinoRank_com",
    ],
  }
}

function jsonSchemaWebsite(props) {
  const { pageContext } = props
  const { site } = pageContext

  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: site?.name,
    url:
      site?.url.indexOf("https://") === 0 ? site?.url : "https://" + site?.url,
    inLanguage: site?.language?.languageTag,
  }
}

function jsonSchemaNavigation(props) {
  const { pageContext } = props
  const { mainMenu = [], site } = pageContext

  return {
    "@context": "https://schema.org",
    "@graph": mainMenu?.map((m, i) => {
      return {
        "@type": "SiteNavigationElement",
        name: m.title,
        url:
          site?.url.indexOf("https://") === 0
            ? site?.url + m.href
            : "https://" + site?.url + m.href,
      }
    }),
  }
}

const Index = (props) => {
  const { pageContext, scrollPosition } = props
  const { page = {}, modules = {}, site = {}, themeSettings } = pageContext
  const { complianceMode } = themeSettings;
  const {
    postList,
    featuredProviders,
    frontpageTaxonomyContent = {},
    menu,
    recentProviders,
    popularGames,
    findProviderBy,
    allProvidersPage,
    contentSectionsAnchorMenu,
    contentSections,
  } = modules
  const pageTitle = page?.title
    ?.replace("%COUNT", "10")
    .replace("[%s:count]", "10")

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      htmlTitle={
        page?.["html-title"]
          ?.replace("%COUNT", "10")
          .replace("[%s:count]", "10") || pageTitle
      }
      metaDescription={page["meta-description"]}
      path="/"
      scrollPosition={scrollPosition}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonSchemaWebsite(props))}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(jsonSchemaOrganization(props))}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(jsonSchemaNavigation(props))}
        </script>
      </Helmet>

      <HeroSection>
        <Grid className="py-3">
          <Row className="contentContainer ml-0 align-items-center">
            <Col xs={12} md={8} className="mt-2 mt-md-0 pl-0">
              <h1 className="mb-2">{pageTitle}</h1>

              {page?.content?.length > 600 ? (
                <ExpandableText
                  {...props}
                  content={page?.content || ""}
                  className="text-left position-relative mainContent"
                  color="#fff"
                />
              ) : (
                <div
                  className="mainContent"
                  dangerouslySetInnerHTML={{ __html: page?.content }}
                />
              )}
            </Col>
            <Col md={4} className="d-flex">
              <HeroImg
                loading="lazy"
                src="/img/hero.svg"
                className="d-none d-md-block"
                alt={pageTitle}
              />
            </Col>
          </Row>
        </Grid>
      </HeroSection>

      {menu ? (
        <TabBar
          {...props}
          menu={menu}
          className=""
          scrollPosition={scrollPosition}
        />
      ) : null}

      <StyledSection secondaryBackground className="pt-3 pt-md-4">
        <Grid className="px-1 px-md-0">
          <h2 className="mb-4">{site?.["start-page-provider-list-title"]}</h2>
          <ProviderList
            {...props}
            providers={JSON.parse(JSON.stringify(featuredProviders))}
            dynamic
            className="px-0"
          />
        </Grid>
      </StyledSection>

      {popularGames?.items?.length ? (
        <Section background="#fff" className="pt-5">
          <Grid className="pt-3">
            <h2 className="text-center mt-5">
              {site["popular-games-heading"] || popularGames?.parent?.name}
            </h2>
            <div className="intro mb-5">
              {popularGames?.parent?.teaser ||
                "Find the best place to play your favorite games"}
            </div>
            <FeaturedTaxonomyGrid data={popularGames?.items ?? []} />
          </Grid>
        </Section>
      ) : null}

      <StyledSection secondaryBackground className="pb-5">
        <Grid>
          <h2 className="text-center mt-5">
            {site["browse-categories-title"] || "Browse casinos by.."}
          </h2>
          <div className="intro mb-5">{site?.["browse-casinos-teaser"]}</div>
          <BrowseProvidersBy
            {...props}
            data={findProviderBy}
            scrollPosition={scrollPosition}
          />
        </Grid>
      </StyledSection>

      {postList && postList?.length ? (
        <Section className="text-center pb-5 pt-5">
          <Grid>
            <h2 className="text-center">
              {dynamicString({
                inputString: site["featured-news-heading"],
              }) || "News"}
            </h2>
            <div className="intro mb-5">
              {modules?.newsTaxonomyPath?.teaser}
            </div>
            <PostGrid
              {...props}
              posts={postList}
              scrollPosition={scrollPosition}
              showMoreButton={true}
              className="mt-5"
            />

            <Button
              className="mt-2 mt-md-3"
              href={modules?.newsTaxonomyPath?.path}
            >
              {site?.["featured-news-heading"] || "News"}
            </Button>
          </Grid>
        </Section>
      ) : null}

      <StyledSection secondaryBackground className="text-center pb-5">
        <Grid>
          <h2 className="text-center mt-5">
            {site["recent-providers-heading"] || "New casinos"}
          </h2>
          <div className="intro mb-5">{site?.["latest-casinos-teaser"]}</div>
          {!complianceMode ? (
            <FeaturedProviders
              {...props}
              providers={recentProviders}
              scrollPosition={scrollPosition}
              count={5}
              filterRestricted={site?.properties?.country?.data ? true : false}
              outboundClickPrefix="recent_"
            />
          ) : null}
          {!complianceMode && allProvidersPage ? (
            <div style={{ display: "flex" }}>
              <Button href={allProvidersPage.path} className="mt-3">
                {allProvidersPage.title}
              </Button>
            </div>
          ) : null}
        </Grid>
      </StyledSection>

      {contentSections?.length ? (
        <Section className="pt-5">
          <Grid>
            <Row className="justify-content-center flex-column align-items-center">
              <Col>
                <div
                  className="text-center mt-5"
                  id={contentSections?.[0]?.headingId}
                  dangerouslySetInnerHTML={{
                    __html: contentSections?.[0]?.heading,
                  }}
                />
              </Col>
              <Col
                md={8}
                className="intro"
                dangerouslySetInnerHTML={{
                  __html: contentSections?.[0]?.content,
                }}
              />
            </Row>
          </Grid>
        </Section>
      ) : null}

      {contentSectionsAnchorMenu?.length ? (
        <Section className="py-5 px-3">
          <Grid>
            <ContentSectionsMenu className="px-2 px-md-5 py-3">
              {contentSectionsAnchorMenu?.map((s) => {
                return (
                  <Col md={6} className="py-2">
                    <ArrowRight className="mr-2" />
                    <a href={"#" + s.slug}>{s.heading}</a>
                  </Col>
                )
              })}
            </ContentSectionsMenu>
          </Grid>
        </Section>
      ) : null}

      <Section background="#fff">
        <Grid className="my-0 py-0">
          <ContentSections
            data={contentSections}
            skip={1}
            scrollPosition={scrollPosition}
          />
          <Row>
            <Col xs={12} md={12} className="pr-md-5">
              {!complianceMode && featuredProviders?.length ? (
                <div className="my-5">
                  <h3>
                    {site?.["start-page-provider-table-title"] || pageTitle}
                  </h3>
                  <ProviderTable
                    {...props}
                    providers={featuredProviders}
                    path="index"
                  />
                </div>
              ) : null}
            </Col>
          </Row>

          {frontpageTaxonomyContent?.sections ? (
            <ContentSections data={frontpageTaxonomyContent?.sections} />
          ) : null}
        </Grid>
      </Section>

      {page?.faq || frontpageTaxonomyContent?.faq ? (
        <StyledSection secondaryBackground className="text-center pb-5">
          <Grid>
            <h2 className="mt-5">FAQ</h2>
            <div className="intro mb-5">{site?.["faq-teaser"]}</div>

            {page.faq ? <Faq content={page.faq} /> : null}
            {frontpageTaxonomyContent?.faq ? (
              <Faq content={frontpageTaxonomyContent?.faq} />
            ) : null}
          </Grid>
        </StyledSection>
      ) : null}
    </Layout>
  )
}

export default trackWindowScroll(Index)
